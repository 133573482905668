import type { ClaimedReward } from '~/types/reward'

export function useClaimedReward() {
  const hasPromocodeReward = (claimedReward: ClaimedReward) => {
    return !!claimedReward.promocode && !!claimedReward.promocode.reward
  }

  const isBadge = (claimedReward: ClaimedReward) => {
    if (!hasPromocodeReward(claimedReward)) return false
    return claimedReward.promocode.reward.promo_type === 'BAD'
  }

  const isDirectDistributionMode = (claimReward: ClaimedReward) => {
    if (!hasPromocodeReward(claimReward)) return false
    return claimReward.promocode.reward.distrib_mode === 'DIR'
  }

  const isCustomReward = (claimedReward: ClaimedReward) => {
    if (!hasPromocodeReward(claimedReward)) return false
    return claimedReward.promocode.reward.distrib_mode === 'CUS'
  }

  const addToCart = (claimedReward: ClaimedReward) => {
    useStoreStore().addRewardToCart(claimedReward)
  }

  const canAddToCart = (claimReward: ClaimedReward) => {
    return (
      useEmbedded().isEmbedded.value &&
      useStoreStore().store?.provider === 1 &&
      hasPromocodeReward(claimReward) &&
      claimReward.promocode.reward.is_external &&
      (['SHI', 'PER', 'BON'].includes(claimReward.promocode.reward.promo_type) ||
        (['BXGY', 'BXGYP', 'PRO'].includes(claimReward.reward.promo_type) &&
          (claimReward.reward.variant_ids.length === 1 ||
            claimReward.promocode.reward?.variant_external_ids.length === 1)))
    )
  }

  return {
    addToCart,
    canAddToCart,
    hasPromocodeReward,
    isBadge,
    isCustomReward,
    isDirectDistributionMode
  }
}
