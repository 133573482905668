import type { Reward } from '~/types/reward'

export const filterActiveReward = (reward: Reward) => {
  const { promo_type, validity_date, is_birthday, is_soldout } = reward

  if (promo_type === 'GIF' || is_birthday || is_soldout) return false

  const dayjs = useDayjs()
  return validity_date === null || dayjs(validity_date) >= dayjs()
}
