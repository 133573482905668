import { useQuery } from '@tanstack/vue-query'
import type { VipLevel } from '~/types/vip'

export const useStoreVip = defineStore('vip', () => {
  const getVipLevels = (companyId: number) => {
    return useQuery({
      queryKey: ['vipLevels', companyId],
      queryFn: async () => {
        try {
          return (await useNuxtApp().$api(`/api/creator/company/${companyId}/vip-tiers/levels/`)) as VipLevel[]
        } catch (error) {
          return []
        }
      },
      placeholderData: [] as VipLevel[],
      staleTime: 1800000 // 30 minutes
    })
  }

  return { getVipLevels }
})
