<template>
  <div class="loader-skeleton">
    <div class="shimmer" />
    <slot />
  </div>
</template>

<style lang="css" scoped>
  .loader-skeleton {
    position: relative;
    width: fit-content;
    overflow: hidden;

    .shimmer {
      transform: translateX(-100%);
      animation: shimmer 1.5s infinite;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
</style>
